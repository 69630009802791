const DASHBOARD_SHARED = {
    pendingDocumentCard: {
        title: `Verifica i documenti!`,
        description: `Per garantire la sicurezza e l'affidabilità delle aziende all'interno della dashboard, 
                    è necessario completare la verifica dei documenti. Una volta verificati,
                    avrai accesso completo come admin dell'azienda selezionata e 
                    tutte le tue creazioni saranno attive.`,
        button: `Verifica i documenti e diventa admin`,
    },
    goToShop: `Vai allo shop`,
    goToHandleChassis: `Vai alla gestione chassis`,
    maxLenght30: `Lunghezza massima 30 caratteri`,
    slotNumber: `Slot {{slotNumber}}`,
    slotCounter: `Slot {{slotCounter}}/{{totalSlots}}`
}

export const DASHBOARD_TRANSLATIONS_IT = {
    dashboard: {
        homePage: {
            helloText: `Ciao, {{name}}`,
            pendingDocumentCard: {
                title: DASHBOARD_SHARED.pendingDocumentCard.title,
                description: DASHBOARD_SHARED.pendingDocumentCard.description,
                button: DASHBOARD_SHARED.pendingDocumentCard.button,
            },
            serviceText: `I servizi Albicchiere`,
            elabel: {
                title: `Elabel`,
                description: `Crea etichette digitali personalizzate in modo semplice e immediato,
                 grazie alla nostra interfaccia user friendly. Rispetta le normative e offri ai tuoi
                  consumatori fidelizzati un’esperienza enologica unica.`,
            },
            wine: {
                title: `Wine`,
                description: `Crea e gestisci i tuoi vini! Arricchisci le schede enologiche e migliora
                 l’esperienza dei tuoi consumatori, per una gestione del vino senza pari. Inizia ora a
                  esplorare e a gestire i vini che produci con Albicchiere!`,
            },
            serviceButton: `Vai`,
        },
        elabel: {
            homePage: {
                title: `ELabel`,
                counter: {
                    scans: `Scansioni`,
                    elabelNumber: `Etichette digitali`,
                    vintages: `Annate`,
                },
                statistics: {
                    comingSoon: `Coming soon!`,
                    title: `Statistiche eLabel`,
                    description: `Sono in arrivo le statistiche per le tue eLabel. Scopri quali sono i tuoi vini più scansionati,
                        in quale regione mondiale e in quali fasce orarie.`,
                },
                table: {
                    title: `Le tue etichette digitali`,
                    column: {
                        wineName: `Nome vino`,
                        updatedAt: `Ultima modifica`,
                        model: `Formato`,
                        lotCounter: `Numero lotti`,
                        scans: `Scansioni`,
                    }
                }
            },
            creation: {
                title: {
                    newElabel: `Creazione eLabel`,
                    newLot: `Aggiungi nuovo lotto`,
                    editElabel: `Modifica eLabel`,
                    editLot: `Modifica lotto`,
                },
                button: {
                    prev: `Indietro`,
                    next: `Continua`,
                    save: `Salva`,
                },
                stepName: {
                    wine: `Wine`,
                    nutrition: `Nutrition`,
                    package: `Package`,
                    layout: `Layout`,
                },

                stepComponents: {
                    stepOne: {
                        languageBox: {
                            title: `Lingua`,
                            description: `Seleziona la lingua di creazione della tua eLabel.
                    Verrà utilizzata come lingua di riferimento per generare le traduzioni automatiche.`,
                            inputTitle: `Lingua di creazione`,
                            inputPlaceholder: `Seleziona la lingua`,
                        },
                        wineBox: {
                            title: `Wine`,
                            description: `Seleziona il vino e l’annata per le quali intendi creare la tua eLabel.`,
                            wineInput: `1.Vino`,
                            createWineButton: `Crea nuovo vino`,
                            vintageInput: `2.Annata`,
                            createVintageButton: `Crea nuova annata`,
                            winePlaceholder: `Seleziona un vino`,
                            vintagePlaceholder: `Seleziona un annata`,
                        }
                    },
                    stepTwo: {
                        nutritionBox: {
                            title: `Nutrizione`,
                            description: `   Inserisci residuo e acido tartarico per calcolare automaticamente i valori energetici del
                            tuo vino.`,
                            alcoholInput: `Volume alcolico (i)`,
                            sugarInput: `Residuo zuccherino`,
                            tartaricInput: `Acido tartarico`,
                            caloriesText: `Valore energetico per 100ml:`,
                            caloriesValue: `{{calories}} kcal`,
                            calcSectionTitle: `Valori calcolati`,
                            calcSectionDescr: `I valori sono automaticamente calcolati da volume alcolico, residuo zuccherino e acido
                            tartarico.`,
                            eneryInput: `Energia`,
                            carbsInput: `Carboidrati`,
                            sugarCarbsInput: `di cui zuccheri`,
                            residualValueSectionTitle: `Valori residuali`,
                            residualValueSectionDescr: `I valori sono tipicamente pari a 0 e possono essere mostrati come piccole quantità.`,
                            fatInput: `Grassi`,
                            saturatedFatInput: `Acidi grassi saturi`,
                            proteinInput: `Proteine`,
                            saltInput: `Sale`,
                        },
                        ingredientsBox: {
                            title: `Ingredienti`,
                            description: `Seleziona gli ingredienti che devono essere dichiarati nella tua elabel. Ti ricordiamo che nella
                    selezione degli ingredienti si devono rispettare le normative nazionali di riferimento. Di seguito
                    sono elencati solo gli ingredienti consentiti dall’UE per la produzione specifica del vino.`,
                            smallQuantities: `piccole quantità`,
                            allergen: `allergene`,
                            addButton: `Aggiungi`,

                        },
                        ingredientsPanel: {
                            title: `Ingredienti`,
                            description: `
                            Seleziona gli ingredienti che devono essere dichiarati nella tua elabel. Ti ricordiamo che
                            nella
                            selezione degli ingredienti si devono rispettare le normative nazionali di riferimento. Di
                            seguito
                            sono elencati solo gli ingredienti consentiti dall’UE per la produzione specifica del vino.`,
                            infoText: `Per saperne di più`,
                            button: `Continua`,
                        }
                    },
                    stepThree: {
                        packagingBox: {
                            title: `Imballaggio`,
                            description: `Seleziona il formato del tuo imballaggio e inserisci i materiali di cui è
                composto se vuoi consigliare le
                modalità di riciclo ai tuoi consumatori (tale sezione è obbligatoria per i prodotti venduti nel mercato
                Italiano).`,
                            modelInput: `Seleziona il formato e la dimensione del tuo imballaggio *`,
                            modelPlaceholder: `Seleziona un formato`,
                            enviroment: {
                                title: `Etichetta ambientale`,
                                description: `Attiva questa sezione per mostrare le informazioni di riciclo. Ricorda che per i prodotti venduti in
                    Italia è obbligatorio mostrare i materiali di imballaggio e altri dettagli.`,
                            },
                            components: {
                                title: `Componenti e materiali dell’imballaggio`,
                                description: `Aggiungi i componenti che formano il tuo imballaggio oppure importali attraverso un template salvato
                    in precedenza.`,
                                importButton: `Importa da template`,
                            },
                            saveTemplateText: `Premi “Salva come template” per creare un nuovo template e riutilizzarlo in futuro.`,
                            saveTemplateButton: `Salva come template`,
                        },
                        certificateBox: {
                            title: `Certificati`,
                            description: `Seleziona i certificati di sostenibilità del tuo vino.`,
                        },
                        warningBox: {
                            title: `Avvertenze`,
                            description: `Seleziona i messsaggi di consumo consapevole che intendi mostrare nella eLabel`,
                        },
                        productionInfoBox: {
                            title: `Informazioni di produzione`,
                            description: `Seleziona i dati relativi a produttore, imbottigliatore e importatore per ogni
                paese in cui il tuo prodotto è
                commercializzato.`,
                        },
                        legalInfoBox: {
                            title: `Avvisi di legge`,
                            description: `Inserisci l’impressum per rispettare le normative locali. Ti ricordiamo che per questo modulo non è
                presente alcuna traduzione automatica, quindi dovrai inserire il testo direttamente nella lingua del
                mercato.`,
                        },
                        productionOperators: {
                            bottler: `Imbottigliatore`,
                            importer: `Importatore`,
                            producer: `Produttore`,
                        },
                        //dialog panels
                        modelImportPanel: {
                            title: `Seleziona Modello`,
                            subtitle: `Nome del modello`,
                            description: `Digita il nome che verra Importato per il modello`,
                        },
                        componentsAddPanel: {
                            title: `Componente e materiale`,
                        },
                        certificatePanel: {
                            title: `Certificati`,
                            description: `Seleziona i certificati di sostenibilità del tuo vino. Puoi aggiungere codice e data di conferimento per
            ogni certificato.`,
                            codePlaceholder: `Codice`,
                            dataPlaceholder: `Data`,
                        },
                        warningPanel: {
                            title: `Avvertenze`,
                            description: `Seleziona i messsaggi di consumo consapevole che intendi mostrare nella eLabel.`,
                        },
                        productionInfoPanel: {
                            title: `Informazioni di produzione`,
                            description: `Seleziona il tipo di operatore, inserendo ragione sociale e indirizzo. Indica per quali Paesi l’operatore
            deve essere visualizzato.`,
                        },
                        legalInfoPanel: {
                            title: `Avvisi di legge`,
                            description: `Inserisci l’impressum e seleziona i paesi per i quali vuoi mostrare questo messaggio.`,
                        },
                        newModelPanel: {
                            title: `Salvataggio template`,
                            subtitle: `Nome del modello`,
                            description: `Inserisci il nome che vuoi associare al tuo template per salvarlo`,
                            namePlaceholder: `Nome`,
                        },
                    },
                    stepFour: {
                        templateStyleBox: {
                            title: `Stile del template`,
                            description: `Rendi unica e riconoscibile la tua etichetta digitale. Personalizza lo stile della tua pagina eLabel
                                          abbinandola ai colori del tuo brand o al packaging del tuo vino. Ogni lotto contenuto in questa eLabel
                                          erediterà le stesse impostazioni.`,
                            backgroundInput: `Colore del background`,
                            sheetInput: `Colore delle schede`,
                            colorInfoText: `*Ti consigliamo di scegliere dei colori chiari e tenui per permettere la lettura dei testi di colore
            nero.`,
                        },
                        qrStyleBox: {
                            title: `Stile del QR code`,
                            description: `Scegli la configurazione, il colore e la trasparenza del tuo QR code. Personalizzalo in base alle tue
                esigenze.`,
                            qrColor: {
                                title: `Colore QR`,
                                description: `Cambia il colore delle linee presenti nel tuo QR code`,
                                transparencyTitle: `Applica trasparenza`,
                                transparencyDescription: `Applicando la trasparenza potrai salvare il file senza sfondo bianco.`,

                            },
                            patternTitle: `Pattern`,
                            previewTitle: `Preview`,
                        },
                    },
                    createVintage: {
                        title: `Elabel - Create Vintage`,
                    }
                }
            },
            detail: {
                title: `Panoramica`,
                qrBox: {
                    title: `eLabel QR`,
                    description: `Scarica il QR Code dell’etichetta creata, che sarà disponibile solo dopo la pubblicazione e
                                    rimarrà accessibile per più lotti. Per un lotto specifico, usa i QR Code dedicati.`,
                },
                table: {
                    title: `Lotti della tua eLabel`,
                    editElabelButton: `Modifica eLabel`,
                    addLotButton: `Aggiungi lotto`,
                    depublishButton: `Nascondi`,
                    publishButton: `Pubblica`,
                    publicLabel: `Pubblicato`,
                    draftLabel: `Bozza`,
                    columns: {
                        state: `Stato`,
                        lotName: `Nome lotto`,
                        lastUpdate: `Ultima modifica`,
                        scans: `Scansioni`,
                    },
                    deleteLotPanel: {
                        title: `Elimina lotto`,
                        subtitle: `Attenzione! Stai per eliminare la eLabel di questo lotto`,
                        description: `Sei sicuro di volere eliminare la elabel di questo lotto? Procedendo, tutti i dati verranno cancellatti e non
            potrai recuperarli.`,
                        deleteButton: `Elimina`,
                    },
                    depublishPanel: {
                        title: `Depubblicazione Lotto`,
                        subtitle: `Questo lotto non sarà più visibile online!`,
                        //desciption use white-space:pre-line -> to go to new line after first phrase
                        description: `Lo stato di questo lotto passerà da “attivo” a “draft”. 
            Questo lotto non sarà più raggiungibile dai tuoi consumatori, sei sicuro di voler procedere? Premi Depublish per depubblicare il lotto. Premi X per tornare indietro.`,
                        depublishButton: `Nascondi`,
                    },
                    publishPanel: {
                        title: `Pubblica lotto`,
                        description: `Premi “Pubblica” per pubblicare il lotto, fai X per tornare indietro. Una volta pubblicata, la
            tua pagina sarà raggiungibile tramite qr o link.`,
                        lotNameTitle: `Nome Lotto`,
                        lotNameDescription: `Dai un nome al tuo lotto se prevedi di produrre più lotti per la stessa annata. Questo nome permette al tuo
            consumatore di visualizzare l’eLabel del lotto corretto. Ti consigliamo di utilizzare lo stesso nome che
            stamperai nella tua etichetta fisica.`,
                        publishButton: `Pubblica`,
                    }
                }
            }
        },
        wine: {
            homePage: {
                title: `Vini`,
                counter: {
                    wines: `Vini`,
                    vintages: `Annate`,
                    elabels: `Elabel`,
                },
                table: {
                    title: `I vini che produci`,
                    searchPlaceholder: `Cerca`,
                    column: {
                        wineName: `Nome vino`,
                        lastUpdate: `Ultima modifica`,
                        averageRating: `Voto medio`,
                    }
                }
            },
            create: {
                title: `Creazione Vino`,
            },
            detail: {
                title: `Dettaglio vino`,
                addVintageButton: `Aggiungi annata`,
                infoText: `Ricorda di completare la scheda enologica e controllare la correttezza delle informazioni.`,
                wineDataBox: {
                    color: `Colore`,
                    region: `Regione`,
                    grapes: `Uve`,
                },
                vintageDetailBox: {
                    title: `Dati dell'annata`,
                    temperature: `Temperatura di servizio`,
                    alcohol: `Volume alcolico`,
                    allergens: `Allergeni`,
                    philosophy: `Filosofia`,
                    descriptionField: `Descrizione`,
                    pairings: `Abbinamenti`,
                    moods: `Mood`,
                    awards: `Riconoscimenti`,
                }
            },
            editWine: {
                title: `Modifica dell'annata`,
                wineCreationDescription: `I dati del vino sono comuni a tutte le annate. Modificandoli in una scheda vintage, 
                le modifiche saranno estese a tutte le altre annate dello stesso vino collegato.
                 Se vuoi procedere comunque a modificare i campi del vino, sblocca il selettore a destra.`,
            },
            addVintage: {
                title: `Aggiungi una nuova annata`
            }
        },
        company: {
            homePage: {
                title: `Company`,
                seeDetailButton: `Vedi dettaglio`,
                usersTitle: `Utenti collegati`,
                pendingDocumentCard: {
                    title: DASHBOARD_SHARED.pendingDocumentCard.title,
                    description: DASHBOARD_SHARED.pendingDocumentCard.description,
                    button: DASHBOARD_SHARED.pendingDocumentCard.button,
                },
                partnerBox: {
                    title: `Partner`,
                    description: `Coming soon! Il nuovo servizio di gestione tramite aziende partner per semplificare il tuo lavoro.
                    Finalmente potrai delegare la gestione del tuo workspace a professionisti di fiducia.`,
                },
                serviceBox: {
                    title: `Area servizi`,
                    description: `Coming soon! In arrivo l'area servizi, dove potrai gestire i tuoi servizi attivi e sottoscriverne di
                    nuovi, pensata per cantine e professionisti del vino.`,
                },
                table: {
                    title: `Location dell'azienda`,
                    columns: {
                        name: `Nome`,
                        address: `Indirizzo`,
                        users: `Utenti`,
                    }
                },
                addressPanel: {
                    title: `Aggiungi Indirizzo`,
                }
            },
            documentVerification: {
                title: `Verifica documenti`,
                description: `La sicurezza delle company ci sta a cuore.
                             Per evitare che persone non autorizzate possano accedere al profilo della tua company, 
                             abbiamo bisogno di alcuni documenti di verifica. Scopri i documenti che puoi 
                             caricare per diventare l’admin della azienda e rendere effettive tutte le creazioni.`,
                noDocumentsLoaded: `Nessun documento caricato`,
                personalDocument: `Documenti personali`,
                companyDocuments: `Documenti aziendali`,
                loadDocumentsButton: `Carica Documenti`,
                findOutButton: `Scopri quali`,
                findOutPanel: {
                    title: `Documenti di riconoscimento`,
                    description: `Per dimostrare che sei il proprietario della company che stai 
                                    reclamando dovrai puoi caricare alcuni tra i seguenti documenti.`,
                    personalTitle: `Documenti personali: `,
                    companyTitle: `Documenti aziendali: `,

                    bankStatement: `Estratto conto`,
                    registrationCertificate: `Visura`,
                    companyBilling: `Bolletta`,

                    identityCard: `Carta d'identità`,
                    drivingLicence: `Patente di guida`,
                    passport: `Passaporto`,
                }
            },
            detail: {
                title: `Dettaglio azienda`,
                formTitle: `Dati azienda`,
                formDescription: `Controlla e, se necessario, modifica i dati della tua azienda.`,
            },
            user: {
                title: `Utenti collegati`,
                companyAdmin: `Admin dell'azienda`,
                connectedUser: `Utenti collegati`,
                locationSelectPlaceholder: `Seleziona una location`,
                approveButton: `Approva`,
                declineButton: `Declina`,
                pendingText: `Vuole collegarsi alla tua azienda`,
                roleEditPanelTitle: `Role Edit`,
                userAcceptPanelTitle: `Role Assignation`,
                inviteUserPanel: {
                    title: `Invita al tuo Workspace`,
                    description: `Stai invitando un nuovo utente alla tua azienda. Inserisci 
                                    la sua email e seleziona un ruolo, poi premi invia invito.`,
                    emailPlaceholder: `Email`,
                    accessTitle: `Accesso`,
                    accessDescription: `Seleziona company se vuoi assegnare un suolo ruolo per tutta la company al tuo utente.
                                         Invece, seleziona location se vuoi assegnare un al to utente un ruolo diverso
                                          per ogni location della tua company.`,
                    roleTitle: `Ruolo`,
                    locationRoleDescription: `Seleziona la location e il relativo ruolo che intendi assegnare all’utente. Fai salva per continuare.`,
                    companyRoleDescription: `Seleziona il ruolo e fai salva per continuare.`,
                    addLocation: `Aggiungi location`,
                },
                invitedUserText: `Utente invitato`,
                activeInviteTagLabel: `Invito attivo`,
                expiredInviteTagLabel: `Invito scaduto`,
                resendInviteButton: `Invita nuovamente`,
                inviteExpirationText: `Scadenza: {{expiryAt}}`,
            },
            locationDetail: {
                title: `Dettaglio Location`,
                isBilling: `Fatturazione`,
                isShipping: `Spedizione`,
                locationAdminText: `{{name}} è l'admin della Location`,
                userNumberText: `{{usersLength}} Utenti`,

                companyAdminTitle: `Admin dell'azienda`,
                locationUserTitle: `Utenti della location`,
                locationEditPanelTitle: `Dati Location`,
                safeDeletePanel: {
                    title: `Elimina Location`,
                    subtitle: `Stai cancellando la location {{locationName}}`,
                    description: `Procedendo, l’utente non potrà più lavorare per la company. Premi rimuovi per scollegare lo
                user, invece
                premi x per tornare indietro.`,
                }
            }
        },
        dispenser: {
            homePage: {
                topBar: {
                    title: `Dispenser`,
                    companyText: `Vai nella sezione <a href="dashboard/company">Azienda</a> per
                        creare
                        una nuova location.`,
                },
                errorCard: {
                    title: `Alert dai dispenser`,
                    switchText: `Mostra tutti`,
                    errorText: `Rilevato "{{error}}" su {{dispenserName}}, nella location {{locationName}}`,
                },
                dispenserSectionTitle: `I tuoi Albi Home`,
                chassisSectionTitle: `I tuoi Albi Pro`,
                chassisButton: DASHBOARD_SHARED.goToHandleChassis,
                slotCounter: DASHBOARD_SHARED.slotCounter,
                slotNumber: DASHBOARD_SHARED.slotNumber,
                shopCard: {
                    text: `Aggiungi Smart Wine Bags e Smart Dispenser direttamente dalla tua dashboard.`,
                    button: DASHBOARD_SHARED.goToShop,
                },
                spaceHandleTitle: `Mantieni la gestione di ogni spazio`,
                handleButton: `Gestisci`,
                chassisCard: {
                    title: `Chassis`,
                    text: `Ordina i dispenser Pro`,
                },
                roomCard: {
                    title: `Stanze`,
                    text: `Posiziona i tuoi dispenser`,
                },
                emptyLocationText: `Aggiungi un dispenser a questa location per 
                massimizzare i tuoi profitti`,
                emptyLocationButton: DASHBOARD_SHARED.goToShop,
            },
            dispenserDetail: {
                title: `Dettaglio dispenser`,
                alertTitle: `Alert dal dispenser`,
                bagId: `bag Id {{bagSerial}}`,
                chassisData: {
                    slotNumber: DASHBOARD_SHARED.slotNumber,
                    roomLocation: `in {{locationName}}, {{roomName}}`,
                },
                location: `Location`,
                room: `Stanza`,
                coolingMode: `Cooling Mode`,
                wifi: `Wifi`,
                batteryLevel: {
                    label: `Livello batteria`,
                    none: `Assente`,
                    empty: `Vuota`,
                    low: `Basso`,
                    half: `Metà`,
                    full: `Piena`,
                    emptyCharging: `Vuota, in carica`,
                    lowCharging: `Basso, in carica`,
                    halfCharging: `Metà, in carica`,
                    fullCharging: `Piena, in carica`,
                    plugged: `Collegato`,
                },
                model: `Modello`,
                firmware: `Firmware`,
                macAddress: `MacAddress`,
                productSerial: `Seriale prodotto`,

                editDispenserPanel: {
                    title: `Modifica dispenser home`,
                    description: `Modifica i dati del tuo dispenser.`,
                    name: `Nome dispenser`,
                    nameError: DASHBOARD_SHARED.maxLenght30,
                    room: `Stanza`,
                    coolingMode: `Cooling mode`,
                },
                parentalControlPanel: {
                    activeTitle: `Parental control attivo`,
                    inactiveTitle: `Parental control disattivo`,
                    activeDescription: `Il parental control sul tuo dispenser è ora attivo. Procedendo a disattivarlo, qualunque utente potrà
                 erogare il vino dal dispenser senza autorizzazione dall’app.`,
                    inactiveDescription: `Il parental control sul tuo dispenser è ora disattivo. Procedendo ad attivarlo, il dispenser potrà
                     erogare solo con autorizzazione dall’app.`,
                    activateButton: `Attiva`,
                    deactivateButton: `Disattiva`,
                },
                factoryResetPanel: {
                    title: `Factory data reset`,
                    description: `Procedendo con il factory data reset scollegherai questo dispenser dal tuo account e per utilizzarlo
                nuovamente dovrai effettuare un nuovo pairing. Premi Factory data reset se vuoi scollegare il dispenser,
                invece premi annulla per tornare indietro.`,
                    button: `Factory data reset`,
                }
            },
            roomList: {
                title: `Lista stanze`,
                roomName: `Nome Stanza`,
                dispensers: `Dispositivi associati`,
                location: `Location`,
                newRoomPanel: {
                    title: `Crea nuova stanza`,
                    description: `Inserisci il nome della stanza che vuoi creare e seleziona la location in cui si trova.
                     Premi Continua per salvare oppure annulla per tornare indietro.`,
                    roomName: `Nome stanza`,
                    roomNameError: DASHBOARD_SHARED.maxLenght30,
                }
            },
            roomDetail: {
                title: `Dettaglio stanza`,
                chassisButton: DASHBOARD_SHARED.goToHandleChassis,
                slotNumber: DASHBOARD_SHARED.slotNumber,
                roomEditPanel: {
                    title: `Modifica nome stanza`,
                    description: `Inserisci il nuovo nome della stanza.
                     Non possono esistere stanze con lo stesso nome nella medesima location.`,
                    name: `Nome stanza`,
                    nameError: DASHBOARD_SHARED.maxLenght30,
                },
                moveDispenserPanel: {
                    title: `Sposta dispositivo`,
                    description: `Per poter scollegare un Dispenser o uno Chassis da una stanza è necessario abbinarlo ad una nuova.
Se vuoi eliminare il dispositivo senza abbinarlo ad una nuova stanza entra nel dettaglio e premi elimina. Sarai in grado di ricollegarlo in un secondo momento eseguendo una nuova procedure di pairing.
Premi Continua per salvare oppure annulla per tornare indietro.
`,
                    name: `Nome stanza`,
                },
                deleteRoomPanel: {
                    title: `Elimina stanza`,
                    description: `Sei sicuro di voler eliminare la stanza?
                     Premi Continua per procedere e cancellare la stanza. Se non vuoi procedere allora premi Annulla per tornare indietro.`,
                }
            },
            chassisList: {
                title: `Lista chassis`,
                name: `Nome chassis`,
                dispensers: `Dispenser Pro`,
                slot: `Slot`,
                location: `Location`,
                room: `Stanza`,
                createChassisPanel: {
                    title: `Crea nuovo chassis`,
                    description: `Inserisci il nome dello Chassis che vuoi creare e il numero degli 
                    Slot che può gestire. Seleziona anche la Location e la Stanza dove lo vuoi posizionarlo.
                     Premi Continua per salvare oppure annulla per tornare indietro.`,
                    name: `Nome Chassis`,
                    nameDescription: `Inserisci il nome del tuo chassis. Massimo 30 caratteri.`,
                    nameError: ` Campo obbligatorio, lunghezza massima 30 caratteri `,
                    totalSlots: `Totale Slots`,
                    totalSlotsError: ` Campo obbligatorio, (minimo 2, massimo 7) `,
                    room: `Stanza`,
                    roomError: ` Campo obbligatorio`,
                }
            },
            chassisDetail: {
                title: `Dettaglio chassis`,
                slotCounter: DASHBOARD_SHARED.slotCounter,
                slotNumber: DASHBOARD_SHARED.slotNumber,
                emptySlot: `Slot vuoto`,
                addDispenserText: `Aggiungi un Pro!`,
                editChassisPanel: {
                    title: `Modifica chassis`,
                    description: `Modifica i campi dello chassis
                            Premi Continua per salvare oppure annulla per tornare indietro.`,
                    name: `Nome Chassis`,
                    nameError: DASHBOARD_SHARED.maxLenght30,
                    totalSlot: `Totale Slots`,
                    totalSlotError: `Minimo 2, massimo 7`,
                    room: `Stanza`,
                },
                changeSlotPanel: {
                    title: `Posizione slot`,
                    description: `Modifica la posizione dei dispenser all’interno dello chassis.`,
                    slot: DASHBOARD_SHARED.slotNumber,
                },
                deleteChassisPanel: {
                    title: `Elimina chassis`,
                    description: `Sei sicuro di voler eliminare lo chassis?
                     Premi Continua per procedere e cancellare la chassis.
                      Se non vuoi procedere allora premi Annulla per tornare indietro.`,
                },
            }
        }
    }
}